import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import '../stylesheets/header.css'

const Header = ({ siteTitle }) => (
  <header id="top">
    <div className="wrapper">
      <nav>
        <Link className="nav-link" to="#commissions">
          <p>
            commission<br></br>info
          </p>
        </Link>
        <Link className="nav-link" to="#faq">
          <p>FAQ</p>
        </Link>
        <Link className="nav-link" to="#photo-tips">
          <p>
            photo<br></br>tips
          </p>
        </Link>
      </nav>
      <h1 className="header">
        <Link to="/">{siteTitle}</Link>
      </h1>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  to: PropTypes.string,
  link_text: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
